.horizontal-timeline {
  .time-frame {
    border-bottom: 1px solid $border-color;
    padding-top: 20px;

    &:last-child {
      border-bottom: none;
    }

    .section-time-frame {
      margin-bottom: 30px;
    }

    .event {
      border-left: 1px solid $border-color;
      padding-left: 2rem;
      padding-bottom: 30px;
      position: relative;

      &:last-child {
        border-left: none;
      }

      .event-text {
        line-height: 1;
        font-weight: 400;
        margin-bottom: 0;
        @include ellipsor;
      }

      .tumbnail-views {
        display: inline-block;

        .thumbnail {
          float: left;
          margin-right: 16px;
          margin-top: 1rem;

          img {
            max-width: 100%;
            border-radius: 4px;
          }
        }
      }

      .event-alert {
        margin-top: 1rem;
        padding: 11px 25px;
        background: rgba(theme-color(primary), 0.2);
        width: 80%;
        font-family: $type-1;
        font-size: 13px;
        font-weight: 300;
        border-radius: 4px;
      }

      .event-info {
        margin-top: 0.8rem;
        font-family: $type-1;
        font-size: 12px;
        font-weight: 400;
        color: $text-muted;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -5px;
        height: 10px;
        width: 10px;
        border-radius: 100%;
      }

      &:nth-child(3n+1) {
        &:before {
          background: theme-color(info);
        }
      }

      &:nth-child(3n+2) {
        &:before {
          background: theme-color(success);
        }
      }

      &:nth-child(3n+3) {
        &:before {
          background: theme-color(primary);
        }
      }
    }
  }
}